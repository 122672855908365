<template>
  <div class="exclusive-book">
    <div class="border-radius-6 box-shadow-light-grey padding-20">
      <dy-table
        :dataList="dataList"
        :rowList="rowList"
        :showPagination="true"
        :isUseCheckbox="true"
        :tagInfo="tagInfo"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        height="72vh"
        @pageChange="pageChange"
        @selection-change="selectionChange"
      >
        <template #filter>
          <c-button
            @click="
              (cb) => {
                initData(cb);
              }
            "
            >刷新数据</c-button
          >
          <el-button @click="addItem" type="primary" class="margin-right-ten"
            >新增</el-button
          >
          <el-select
            v-model="params.platform"
            clearable
            filterable
            placeholder="平台"
            class="margin-right-ten"
            style="width: 120px"
            @change="initData"
          >
            <el-option label="掌中云" :value="0"></el-option>
            <el-option label="阅文" :value="1"></el-option>
            <el-option label="阳光书城" :value="3"></el-option>
            <el-option label="悠书阁" :value="4"></el-option>
            <el-option label="网易文鼎" :value="5"></el-option>
            <el-option label="七悦" :value="6"></el-option>
            <el-option label="花生书城" :value="7"></el-option>
            <el-option label="海读" :value="8"></el-option>
            <el-option label="国风" :value="12"></el-option>
          </el-select>
          <!-- <el-select
            v-model="params.bookId"
            placeholder="书籍"
            filterable
            remote
            clearable
            reserve-keyword
            style="width: 150px"
            class="margin-right-ten"
            :remote-method="getBook"
            @change="handleBookChange"
          >
            <el-option
              v-for="(item, index) in bookList"
              :key="index"
              :label="item.bookName"
              :value="item.id"
            ></el-option>
          </el-select> -->

          <el-select
            v-model="params.status"
            style="width: 100px"
            class="margin-right-ten"
            placeholder="状态"
            clearable
            filterable
            @change="initData"
          >
            <el-option label="待执行" :value="1"></el-option>
            <el-option label="不执行" :value="2"></el-option>
            <el-option label="执行成功" :value="3"></el-option>
          </el-select>
          <c-input
            placeholder="书籍名"
            style="width: 200px"
            class="margin-right-ten"
            v-model="params.bookName"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="initData"
            ></el-button>
          </c-input>
          <c-button
            type="danger"
            :autoConfirm="true"
            @confirm="
              (cb) => {
                delBatch(cb);
              }
            "
            :disabled="selection.length === 0"
            >批量删除</c-button
          >
          <span style="float: right; font-weight: bold; color: #fd5d5a"
            >自动同步时间为凌晨1:00开始进行！</span
          >
        </template>
        <template #platform="{ row }">
          <span>{{ getPlatform(row.platform) }}</span>
        </template>
        <template #type="{ row }">
          <span>{{ getType(row) }}</span>
        </template>
        <template #ruledoutAccountChannels="{ row }">
          <span>
            <el-tag
              type="success"
              style="margin: 4px 4px 0 0"
              v-for="item in row.ruledoutAccountChannels"
              :key="item.id"
              >{{ item.channelName }}</el-tag
            >
          </span>
        </template>
        <template #price="{ row }">
          <span
            >{{ row.price
            }}<span style="color: #fd5d5a; font-weight: bold">{{
              unitList[row.platform]
            }}</span></span
          >
        </template>
        <template #operation="{ row }">
          <c-button
            type="primary"
            size="small"
            class="button-small"
            @click="
              (cb) => {
                editItem(row, cb);
              }
            "
            >编辑</c-button
          >
          <c-button
            type="warning"
            size="small"
            class="button-small"
            :autoConfirm="true"
            message="因为同步要进行切号，会对客服消息产生不确定因素，是否继续？"
            @confirm="
              (cb) => {
                syncData(row, cb);
              }
            "
          >
            同步
          </c-button>
          <c-button
            type="danger"
            size="small"
            class="button-small"
            :autoConfirm="true"
            @confirm="
              (cb) => {
                delItem(row.id, cb);
              }
            "
            @cancel="handleButtonCancel"
            >删除</c-button
          >
        </template>
      </dy-table>
    </div>
    <add-exclusive
      :show="showEditDialog"
      :isEdit="isEdit"
      :curInfo="curInfo"
      @close="showEditDialog = false"
      @handleOk="handleOk"
    ></add-exclusive>
  </div>
</template>

<script>
import {
  getExclusiveBook,
  addExclusiveBook,
  editExclusiveBook,
  delExclusiveBook,
  syncExclusiveBookData,
} from "@/api/service.js";
import { getBookList } from "@/api/account.js";
import { platformList } from "@/assets/js/options.js";
import AddExclusive from "./add-exclusive.vue";
export default {
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      params: {},
      isEdit: false,
      showEditDialog: false,
      unitList: {
        0: "书币",
        1: "元",
        2: "书币",
        3: "书币",
        4: "元",
        5: "元",
        6: "书币",
        7: "书币",
        8: "元",
        12: "元",
      },
      curInfo: {},
      selection: [],
      dataList: [],
      bookList: [],
      rowList: [
        {
          label: "id",
          prop: "id",
          width: "50",
        },
        {
          label: "平台",
          prop: "platform",
          slot: true,
        },
        {
          label: "排除公众号",
          prop: "ruledoutAccountChannels",
          slot: true,
          width: "170",
        },
        {
          label: "书籍名",
          prop: "bookName",
          popover: true,
          width: "120",
        },
        {
          // label: '千字价格',
          prop: "price",
          slot: true,
          renderHeader: () => {
            return <span>千字价格</span>;
          },
        },
        // {
        //   label: '章节id',
        //   prop: 'bookArticleId',
        //   popover: true
        // },
        {
          label: "开始收费章节",
          prop: "chapterChargeStart",
          popover: true,
          width: "100",
        },
        {
          label: "状态",
          prop: "status",
          tag: true,
        },
        {
          label: "失败原因",
          prop: "failReason",
          popover: true,
          rowHtml: true,
        },
        {
          label: "类型",
          prop: "type",
          slot: true,
          width: "150",
        },
        {
          label: "操作",
          action: "operation",
          width: 200,
        },
      ],
      tagInfo: [
        {
          tagName: "status",
          tagVal: [
            {
              val: 1,
              text: "待执行",
            },
            {
              val: 2,
              text: "不执行",
            },
            {
              val: 3,
              text: "执行成功",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.initData();
  },
  watch: {},
  methods: {
    initData(cb) {
      getExclusiveBook({
        page: this.page,
        pageSize: this.pageSize,
        bookName: this.params.bookName,
        platform: this.params.platform,
        status: this.params.status,
      })
        .then((res) => {
          res.list.forEach((item) => {
            if (
              item.platform == 5 ||
              item.platform == 12 ||
              item.platform == 8
            ) {
              item.chapterChargeStart = item.ext1;
            }
          });
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          if (typeof cb === "function") {
            cb();
          }
        });
    },
    getBookName() {
      getBookList({
        page: 1,
        pageSize: 50,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    getType({ type }) {
      if (type === 1) return "设置价格";
      if (type === 2) return "生成链接";
      if (type === 3) return "设置价格并生成链接";
    },
    getPlatform(platform) {
      return platformList[platform];
    },
    pageChange(val) {
      this.page = val;
      this.initData();
    },
    editItem(row, cb) {
      this.isEdit = true;
      this.curInfo = row;
      cb();
      this.showEditDialog = true;
    },
    delItem(id, cb) {
      delExclusiveBook(id)
        .then(() => {
          this.$message.success("删除成功!");
          this.initData();
        })
        .finally(() => {
          cb();
        });
    },
    addItem() {
      this.isEdit = false;
      this.curInfo = {};
      this.showEditDialog = true;
    },
    handleButtonCancel() {},
    selectionChange(select) {
      this.selection = select;
    },

    delBatch(cb) {
      const ids = this.selection.map((item) => item.id).join(",");
      delExclusiveBook(ids)
        .then(() => {
          this.$message.success("删除成功!");
          this.initData();
        })
        .finally(() => {
          cb();
        });
    },
    syncData({ id }, cb) {
      syncExclusiveBookData(id)
        .then(() => {
          this.$message.success("同步成功!");
          this.initData();
        })
        .finally(() => {
          cb();
        });
    },
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    handleBookChange() {
      this.initData();
    },
    handleOk({ val, cb }) {
      const obj = {
        bookId: `${val.bookId}`,
        bookName: val.bookName,
        bookArticleId: val.bookArticleId,
        bookArticleIdx: val.bookArticleIdx,
        chapterChargeStart: Number(val.chapterChargeStart),
        platform: val.platform,
        price: parseFloat(val.price),
        status: val.status,
        type: val.type,
        ruledoutAccountChannelIds: val.ruledoutAccountChannelIds,
      };
      if (val.platform === 5 || val.platform === 12 || val.platform == 8) {
        obj.ext = val.ext;
        obj.ext1 = val.ext1;
        obj.chapterChargeStart = val.chapterChargeStart;
      }
      // console.log(obj, val);
      // return;
      if (!this.isEdit) {
        addExclusiveBook(obj)
          .then(() => {
            this.$message.success("新增成功!");
            this.showEditDialog = false;
            this.initData();
            this.resetData();
          })
          .finally(() => {
            if (cb) {
              cb();
            }
          });
      } else {
        editExclusiveBook(this.curInfo.id, obj)
          .then(() => {
            this.$message.success("修改成功!");
            this.showEditDialog = false;
            this.initData();
            this.resetData();
          })
          .finally(() => {
            if (cb) {
              cb();
            }
          });
      }
    },
    resetData() {
      this.isEdit = false;
      this.curInfo = {};
    },
  },
  components: { AddExclusive },
};
</script>
<style lang='scss' scoped>
.exclusive-book {
  background: #fff;
}
</style>
