<template>
  <div>
    <el-dialog
        title="选择章节"
        :visible.sync="showNext"
        width="800px"
        top="5vh"
        append-to-body
        :before-close="()=>$emit('close')"
      >
        <el-table
          :data="chapterList"
          height="600px"
          v-loading="chapterLoading"
          highlight-current-row
          @current-change="handleChartRowChange"
          style="width: 100%"
        >
          <el-table-column prop="select" label="选择" width="50">
            <template slot-scope="scope">
              <el-radio
                type="radio"
                :value="currentChapter ? currentChapter.id : null"
                :label="scope.row.id"
                style="font-size: 0"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column property="id" label="章节ID" width="170">
          </el-table-column>
          <el-table-column property="title" label="章节名称" width="150">
            </el-table-column>
          <el-table-column property="needPay" label="资费" width="70">
            <template slot-scope="scope">
              {{scope.row.needPay?'收费':'免费'}}
            </template>
          </el-table-column>
          <el-table-column property="sourceUuid" label="资源id">
            </el-table-column>
          <!-- <el-table-column property="idx" label="章节下标" width="100"> -->
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 15px; text-align: right"
          background
          @current-change="handleChapterPageChange"
          :current-page="chapterPage"
          :total="chapterTotal"
          :page-size="chapterPageSize"
          layout="total, prev, pager, next, jumper"
        >
        </el-pagination>
        <div class="dialog-footer">
          <el-button @click="showNext = false">取 消</el-button>
          <el-button type="primary" @click="handleSureChart"
            >确 定</el-button
          >
        </div>
      </el-dialog>
  </div>
</template>

<script>
import { getBookChapter } from "@/api/service";
export default {
  props: {
    showNext: Boolean,
    channelId: Number,
    bookId: String | Number,
  },
  data() {
    return {
      chapterList: [],
      chapterPage: 1,
      chapterListTotal: [],
      chapterTotal: 0,
      chapterPageSize: 15,
      chapterLoading: false,
    };
  },
  watch: {
    showNext: function (nv, ov) {
      if (nv) {
        this.getChapterData();
      }
    },
  },
  methods: {
    handleSureChart() {
      this.$emit("success", this.currentChapter);
    },
    handleChartRowChange(row) {
      if (row) {
        this.currentChapter = row;
      }
    },
    handleChapterPageChange(page) {
      this.chapterPage = page;
      this.chapterList = this.chapterListTotal.slice(
        (this.chapterPage - 1) * this.chapterPageSize,
        this.chapterPage * this.chapterPageSize
      );
    },
    getChapterData() {
      this.chapterLoading = true;
      getBookChapter(this.channelId, {
        cbid: this.bookId,
      })
        .then((res) => {
          this.chapterListTotal = res;
          this.chapterTotal = res.length;
          this.chapterList = this.chapterListTotal.slice(
            (this.chapterPage - 1) * this.chapterPageSize,
            this.chapterPage * this.chapterPageSize
          );
          // this.noMoreChapter = res.length < 20;
          this.currentChapter = res[0];
        })
        .catch(() => {
          this.chapterList = [];
        })
        .finally(() => {
          this.chapterLoading = false;
        });
    },
  },
};
</script>

<style>
</style>
