<template>
  <el-dialog
    :title="isEdit ? '编辑素材（“执行成功”的时候不能编辑）' : '新增素材'"
    :visible.sync="show"
    center
    @close="handleClose"
    v-loading="loadingEditor"
    :before-close="handleClose"
    width="700px"
  >
    <div class="container">
      <div class="per-part flex-row-start-center">
        <span class="title required">平台</span>
        <el-select
          v-model="copyCurInfo.platform"
          style="width: 500px"
          placeholder="平台"
          clearable
          :disabled="isEdit === true"
          @change="handlePlatformChange"
        >
          <el-option label="掌中云" :value="0"></el-option>
          <el-option label="阅文" :value="1"></el-option>
          <el-option label="阳光书城" :value="3"></el-option>
          <el-option label="悠书阁" :value="4"></el-option>
          <el-option label="网易文鼎" :value="5"></el-option>
          <el-option label="七悦" :value="6"></el-option>
          <el-option label="花生书城" :value="7"></el-option>
          <el-option label="海读" :value="8"></el-option>
          <el-option label="国风" :value="12"></el-option>
        </el-select>
      </div>
      <div class="per-part flex-row-start-center">
        <span class="title">排除公众号</span>
        <el-select
          v-model="ruledoutAccountChannelIds"
          style="width: 500px"
          placeholder="选择排除公众号"
          clearable
          multiple
        >
          <el-option
            v-for="(item, index) in accountList"
            :value="item.id"
            :label="item.channelName"
            :key="index"
          ></el-option>
        </el-select>
      </div>
      <div class="per-part flex-row-start-center">
        <span class="title required">书籍</span>
        <div class="select-book flex-row-start-center">
          <p>
            <span v-if="bookDetail"
              >{{ bookDetail.book.bookName }}&nbsp;/&nbsp;截止第{{
                bookDetail.chapter.endIdx
              }}章</span
            >
            <span v-else>请选择书籍</span>
          </p>
          <el-button
            type="primary"
            @click="handleBookShow"
            style="margin-left: auto"
            :disabled="getDisabled()"
            >选择书籍</el-button
          >
        </div>
      </div>
      <div class="per-part flex-row-start-center">
        <span class="title required">开始收费章节</span>
        <div
          class="wangyi-chart"
          v-if="
            selectChannel &&
            (selectChannel.platform === 5 ||
              selectChannel.platform === 12 ||
              selectChannel.platform === 8)
          "
        >
          <span>
            <template v-if="copyCurInfo && copyCurInfo.ext1">
              {{ copyCurInfo.ext1 }}
            </template>
            <template v-else> 请选择收费章节 </template>
          </span>
          <el-button
            type="primary"
            @click="handleChooseChart"
            style="margin-left: auto"
            >选择收费章节</el-button
          >
        </div>

        <c-input
          v-else
          style="width: 500px"
          :onlyNum="true"
          :isInter="true"
          :min="0"
          placeholder="请输入收费章节!"
          v-model="copyCurInfo.chapterChargeStart"
        ></c-input>
      </div>
      <div class="per-part flex-row-start-center">
        <span class="title required">千字价格</span>
        <c-input
          style="width: 500px"
          :onlyNum="true"
          :min="0"
          v-model="copyCurInfo.price"
          :disabled="getDisabled()"
        >
        </c-input>
        <span v-if="selectChannel" style="margin-left: 5px">{{
          unitList[selectChannel.platform]
        }}</span>
      </div>
      <div class="per-part flex-row-start-center">
        <span class="title required">状态</span>
        <el-radio-group v-model="copyCurInfo.status">
          <el-radio :label="1">待执行</el-radio>
          <el-radio :label="2" :disabled="Status === 3">不执行</el-radio>
          <template v-if="isEdit === true">
            <el-radio :label="3" disabled>执行成功</el-radio>
          </template>
        </el-radio-group>
      </div>
      <div class="per-part flex-row-start-center">
        <span class="title required">类型</span>
        <el-select
          v-model="copyCurInfo.type"
          style="width: 500px"
          placeholder="类型"
          :disabled="getDisabled()"
        >
          <el-option label="设置价格" :value="1"></el-option>
          <el-option label="生成链接" :value="2"></el-option>
          <el-option label="设置价格并生成链接" :value="3"></el-option>
        </el-select>
      </div>
      <div class="button-list" style="text-align: center">
        <c-button
          type="primary"
          :disabled="getDisabled()"
          @click="
            (fn) => {
              handleOk(fn);
            }
          "
          >确定</c-button
        >
        <c-button
          @click="
            (fn) => {
              handleCancel(fn);
            }
          "
          >取消</c-button
        >
      </div>
    </div>
    <choose-book
      :showBook="showBook"
      @close="showBook = false"
      @success="bookChooseSuccess"
      :channel="selectChannel"
    ></choose-book>

    <!--选择章节-->
    <!-- 选择网易章节 -->
    <wangyiChart
      :showNext="showNext"
      :channelId="selectChannel ? selectChannel.id : 0"
      :bookId="currentBook.bookId"
      @close="showNext = false"
      @success="handleSureChart"
    />
    <haiduChart
      :showNext="showHaiduNext"
      :channelId="selectChannel ? selectChannel.id : 0"
      :bookId="currentBook.bookId"
      @close="showHaiduNext = false"
      @success="handleSureChart"
    />
  </el-dialog>
</template>

<script>
import wangyiChart from "./wangyiChart";
import haiduChart from "./haiduChart";
import ChooseBook from "@/views/message/mediaResource/chooseBook.vue";
import { mapGetters } from "vuex";
import { getBookChapter } from "@/api/service";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    curInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loadingEditor: false,
      showBook: false,
      bookDetail: null,
      selectChannel: null,
      copyCurInfo: {},
      Status: null,
      ruledoutAccountChannelIds: [],
      chapterList: [],
      chapterPage: 1,
      chapterListTotal: [],
      chapterTotal: 0,
      chapterPageSize: 15,
      chapterLoading: false,
      showNext: false,
      showHaiduNext: false,
      currentChapter: {}, //.articleUuid
      submitLoading: false,
      currentBook: {}, //.bookId
      unitList: {
        0: "书币",
        1: "元",
        2: "书币",
        3: "书币",
        4: "元",
        5: "元",
        6: "书币",
        7: "书币",
        8: "元",
        12: "元",
      },
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
    channelList() {
      // return
      const { officialList } = this;
      return officialList.filter((item) =>
        [0, 1, 3, 7].includes(item.platform)
      );
    },
    accountList() {
      return this.officialList.filter(
        (item) => item.platform === this.copyCurInfo.platform
      );
    },
  },
  mounted() {},
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          if (Object.keys(this.curInfo).length > 0 && this.isEdit) {
            this.Status = this.curInfo.status;
            const account = this.officialList.find(
              (item) => item.platform === this.curInfo.platform
            );
            this.selectChannel = {
              id: account.id,
              channelName: account.channelName,
              platform: account.platform,
            };
            this.bookDetail = {
              book: {
                bookName: this.curInfo.bookName,
                bookId: this.curInfo.bookId,
              },
              chapter: {
                endIdx: this.curInfo.bookArticleIdx,
              },
            };
            this.copyCurInfo = JSON.parse(JSON.stringify(this.curInfo));
            this.copyCurInfo.price = `${this.copyCurInfo.price}`;
            if (this.curInfo.ruledoutAccountChannelIds) {
              this.ruledoutAccountChannelIds =
                this.curInfo.ruledoutAccountChannelIds.split(",").map(Number);
            }

            this.copyCurInfo.chapterChargeStart = `${this.copyCurInfo.chapterChargeStart}`;
            if (
              this.selectChannel.platform === 5 ||
              this.selectChannel.platform === 12 ||
              this.selectChannel.platform === 8
            ) {
              this.currentBook.bookId = this.curInfo.bookId;
              this.currentChapter = {};
              // this.copyCurInfo.chapterChargeStart =
              //   this.copyCurInfo.bookArticleIdx;
              // this.currentChapter.articleUuid = "";
              // // 删除回显下标
              // this.$set(this.bookDetail.chapter, "endIdx", "");
            }
          } else {
            //   let account = this.officialList.find(item=>item.id === )
            this.$set(this.copyCurInfo, "status", 1);
          }
        }
      },
    },
  },
  methods: {
    // 国风网易海读
    handleSureChart(data, index) {
      this.currentChapter = data;
      this.copyCurInfo.chapterChargeStart = this.currentChapter.articleUuid;
      this.currentChapter.chapterChargeStart = data.articleUuid;
      this.copyCurInfo.ext = this.currentChapter.sourceUuid;
      this.copyCurInfo.ext1 = this.currentChapter.title;
      if (this.selectChannel.platform == 8) {
        this.currentChapter.ext = index;
        this.currentChapter.chapterChargeStart = data.chapterId;
        this.copyCurInfo.chapterChargeStart = this.currentChapter.chapterId;
        this.copyCurInfo.ext = this.currentChapter.ext;
        this.copyCurInfo.ext1 = this.currentChapter.chapterName;
      }
      this.showNext = false;
      this.showHaiduNext = false;
    },
    handleChooseChart() {
      if (!this.currentBook.bookId) {
        return this.$message.error("请先选择书籍");
      }
      if (
        this.selectChannel.platform === 5 ||
        this.selectChannel.platform == 12
      ) {
        this.showNext = true;
      }
      if (this.selectChannel.platform === 8) {
        this.showHaiduNext = true;
      }
    },
    bookChooseSuccess(val) {
      // 清除当前文章章节
      this.$set(this.copyCurInfo, "chapterChargeStart", "");
      this.copyCurInfo.ext = null;
      this.currentChapter = {};
      this.currentBook = val.book;
      this.bookDetail = val;
      getBookChapter(this.selectChannel.id, { cbid: val.book.bookId }).then(
        (res) => {
          this.$set(this.copyCurInfo, "bookId", val.book.bookId);
          this.$set(this.copyCurInfo, "bookName", val.book.bookName);
          this.$set(
            this.copyCurInfo,
            "bookArticleId",
            val.chapter.curChapterId
          );
          let bookArticleIdx = res.findIndex((item) => {
            if (this.selectChannel.platform === 1) {
              return item.ccid === val.chapter.curChapterId;
            }
            if (this.selectChannel.platform === 7) {
              return item.chapter_id === val.chapter.curChapterId;
            }
            if (this.selectChannel.platform === 6) {
              return item.id === val.chapter.bookArticleId;
            }
            if (this.selectChannel.platform === 8) {
              return item.chapterId === val.chapter.bookArticleId;
            }
            return item.id === val.chapter.curChapterId;
          });
          if (this.selectChannel.platform === 8) {
            bookArticleIdx = bookArticleIdx - 1;
            this.$set(
              this.copyCurInfo,
              "bookArticleId",
              val.chapter.bookArticleId
            );
          }
          this.$set(this.copyCurInfo, "bookArticleIdx", bookArticleIdx + 1);
          // this.$set(
          //   this.copyCurInfo,
          //   'chapterChargeStart',
          //   `${
          //     bookArticleIdx + 1
          //   }`
          // )
        }
      );
    },
    handleBookShow() {
      if (!this.selectChannel) {
        return this.$message.warning("请选择平台!");
      }
      this.showBook = true;
    },
    handlePlatformChange(platform) {
      // * 找到一个即可
      const account = this.officialList.find(
        (item) => item.platform === platform
      );
      if (account) {
        this.selectChannel = {
          id: account.id,
          channelName: account.channelName,
          platform,
        };
      }
    },
    handleOk(fn) {
      if (!this.selectChannel) {
        fn();
        return this.$message.warning("请选择平台!");
      }
      if (
        !this.copyCurInfo.chapterChargeStart ||
        +this.copyCurInfo.chapterChargeStart === 0
      ) {
        fn();
        return this.$message.warning("请输入正确的收费章节!");
      }
      if (this.copyCurInfo.bookId === undefined) {
        fn();
        return this.$message.warning("请选择书籍");
      }
      if (!this.copyCurInfo.price) {
        fn();
        return this.$message.warning("请输入价格!");
      }
      if (!this.copyCurInfo.status) {
        fn();
        return this.$message.warning("请选择状态!");
      }
      if (!this.copyCurInfo.type) {
        fn();
        return this.$message.warning("请选择类型!");
      }
      if (this.ruledoutAccountChannelIds.length) {
        this.copyCurInfo.ruledoutAccountChannelIds =
          this.ruledoutAccountChannelIds.join(",");
      } else {
        this.copyCurInfo.ruledoutAccountChannelIds = "";
      }
      this.$set(this.copyCurInfo, "platform", this.selectChannel.platform);
      if (this.copyCurInfo.status === undefined) {
        fn();
        return this.$message.warning("请选择状态!");
      }
      if (
        this.selectChannel.platform === 5 ||
        this.selectChannel.platform === 8 ||
        this.selectChannel.platform === 12
      ) {
        if (!this.copyCurInfo.chapterChargeStart) {
          fn();
          return this.$message.error("请选择收费章节");
        }
        // 国风、网易文鼎 sourceid
        // this.$set(
        //   this.copyCurInfo,
        //   "chapterChargeStart",
        //   this.currentChapter.chapter.curChapterId
        // );
      }
      if (this.selectChannel.platform == 6) {
        this.$set(
          this.copyCurInfo,
          "bookArticleId",
          this.bookDetail.chapter.bookArticleId
        );
      }
      // if (this.selectChannel.platform === 8) {
      //   if (!this.copyCurInfo.chapterChargeStart) {
      //     fn();
      //     return this.$message.error("请选择收费章节");
      //   }
      //   // console.log();
      // }
      // console.log(this.copyCurInfo, "curinfocopy");
      this.$emit("handleOk", {
        val: this.copyCurInfo,
        cb: () => {
          fn();
          this.resetData();
        },
      });
    },
    resetData() {
      this.copyCurInfo = {};
      this.showBook = false;
      this.bookDetail = null;
      this.selectChannel = null;
      this.ruledoutAccountChannelIds = [];
    },
    handleCancel(fn) {
      fn();
      this.currentChapter = {};
      this.handleClose();
    },
    getDisabled() {
      return this.isEdit === true && [3].includes(this.copyCurInfo.status);
    },
    handleClose() {
      this.resetData();
      this.$emit("close");
    },
  },
  components: { ChooseBook, wangyiChart, haiduChart },
};
</script>
<style lang='scss' scoped>
.container {
  margin: 0 auto;
  .per-part {
    margin-bottom: 20px;
    .title {
      width: 100px;
      text-align: right;
      margin-right: 15px;
    }
    .select-book {
      width: 500px;
    }
  }
}
.wangyi-chart {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
